import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://599e4c67927e098c2a98f1f66f7025ba@sentry.cityflo.net/5",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});
import prefetch from "@astrojs/prefetch/client.js"; prefetch({});